<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <b-link class="brand-logo">
				<b-img
					:src="appLogoImage"
					alt="logo"
					width="220"
				/>
			</b-link>

            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        :src="imgUrl"
                        fluid
                        alt="Register V2"
                    />
                </div>
            </b-col>

            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >

                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Restablecer la contraseña 🔒
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente
                    </b-card-text>

                    <validation-observer ref="resetPasswordForm">

                        <b-form class="auth-reset-password-form mt-2">

                            <!-- NEW PASSWORD -->
                            <div class="mb-2">
                                <label for="account-new-password"><strong>CONTRASEÑA NUEVA</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="contraseña nueva"
                                    vid="Password"
                                    rules="required|password"
                                >
                                    <b-input-group class="input-group-merge">
                                        <b-form-input
                                            id="account-new-password"
                                            :state="errors.length > 0 ? false : null"
                                            v-model="newPasswordValue"
                                            :type="newPasswordFieldType"
                                            name="new-password"
                                            placeholder="********"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="newPasswordToggleIcon"
                                                class="cursor-pointer"
                                                @click="newPasswordToggle"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <!-- RETYPE NEW PASSWORD -->
                            <div class="mb-2">
                                <label for="account-retype-new-password"><strong>REESCRIBA NUEVA CONTRASEÑA</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="confirmar contraseña"
                                    rules="required|confirmed:Password"
                                >
                                    <b-input-group class="input-group-merge">
                                        <b-form-input
                                            id="account-retype-new-password"
                                            v-model="retypePasswordValue"
                                            :type="retypePasswordFieldType"
                                            :state="errors.length > 0 ? false : null"
                                            name="retype-password"
                                            placeholder="********"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="retypePasswordToggleIcon"
                                                class="cursor-pointer"
                                                @click="retypePasswordToggle"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <!-- BUTTON SUBMIT -->
							<b-button-loading 
                                text="ESTABLECER NUEVA CONTRASEÑA" 
                                :processing="processing"
                                :block="true"
                                @process-action="resetPassword">
                            </b-button-loading>

                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{ name:'login' }">
                            <feather-icon icon="ChevronLeftIcon" /> Atrás para iniciar sesión
                        </b-link>
                    </p>

                </b-col>
            </b-col>

        </b-row>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, password, confirmed } from '@validations';
    import { $themeConfig } from '@themeConfig';
    import useJwt from '@/auth/jwt/useJwt';
    import store from '@/store/index';
    import router from '@/router';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading
        },
        data() {
            return {
                sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
                processing: false,

                newPasswordValue: null,
                retypePasswordValue: null,

                newPasswordFieldType: 'password',
                retypePasswordFieldType: 'password',

                required,
                password,
                confirmed
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') 
                {
                    this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg');
                    return this.sideImg;
                }

                return this.sideImg;
            },
            newPasswordToggleIcon() {
                return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
            },
            retypePasswordToggleIcon() {
                return this.retypePasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
            }
        },
        methods: {
            newPasswordToggle() {
                this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password';
            },
            retypePasswordToggle() {
                this.retypePasswordFieldType = this.retypePasswordFieldType === 'password' ? 'text' : 'password';
            },
            resetPassword() {
                this.$refs.resetPasswordForm.validate().then(success => {
                    if (success) {

                        this.processing = true;

						useJwt.resetPassword({
                            token: this.$route.params.token,
                            newPassword: this.newPasswordValue,
                            confirmPassword: this.retypePasswordValue
                        })
                        .then(response => {
                            if (response.data.success) {
                                
                                this.$toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

                                router.push({ name: 'login' })

                            }
                        })
                        .catch(error => {
                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: error.response.data.message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            });

                        })
                        .finally( () => this.processing = false);

                    }
                });
            }
        },
		setup() {
			const { appLogoImage } = $themeConfig.app;

			return {
				appLogoImage
			}
		}
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
